<template>
    <div class="scrap">
        <div class="header">
            <span @click="gouot"></span>
            <span>采购录入</span>
            <span @click="islogout">退出</span>
        </div>
        <!-- <van-popup v-model="showMap" position="button" closeable :close-on-click-overlay="false" :style="{ height: '100%',width:'100%',paddingTop:'20px' }" >
            <iframe id="mapPage" width="100%" height="100%" frameborder=0
                src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=WYZBZ-SEE3X-SYZ4N-7W7QQ-J55VJ-7JFB4&referer=myapp">
            </iframe>
        </van-popup> -->

        <div class="scrap-content">
            <van-form label-width="130px" label-align="right">

                <van-cell-group>
                    <van-switch-cell v-model="isCarInfo" title="录入车辆基本信息"
                        :style="{ color: 'green', fontSize: '30px', fontWeight: 'bloder' }" cell-size="large"
                        disabled />
                </van-cell-group>
                <div v-show="isCarInfo">
                    <div class="scrap-content-div" :style="{ margin: '20px 20px' }">
                        <div :style="{ margin: '5px 10px' }">车牌号码：</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.carNumberPlate" name="" label="" placeholder="请输入车牌号"></van-field>
                    </div>

                    <div class="scrap-content-div" :style="{ margin: '20px 20px' }">
                        <div :style="{ margin: '5px 10px' }">车架号码:</div>
                        <input hidden type="file" ref="filesRef" accept="image/*" @change="afterRead" />
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }" v-model="form.vin"
                            center clearable name="车架号码" label="" placeholder="请输入车架号码">
                            <van-button slot="button" size="small"
                                @click="isWechat ? WeChooseImage(1, 'shibVin') : $refs.filesRef.click()"
                                type="info">VIN识别</van-button>
                        </van-field>
                    </div>

                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">车辆品牌:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.vehicleBrand" name="" label="" placeholder="请输入品牌" />
                    </div>


                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">车辆型号:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.vehicleModel" name="" label="" placeholder="请输入型号" />
                    </div>
                </div>

                <van-cell-group>
                    <van-switch-cell v-model="isPriceInfo" title="录入采购价格信息"
                        :style="{ color: 'green', fontSize: '30px', fontWeight: 'bloder' }" cell-size="large" />
                </van-cell-group>
                <div v-show="isPriceInfo">
                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">预计采购成本:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.purchaseAmount" name="采购成本" type="number" @focus="cleKong(1)" label=""
                            placeholder="请输入预计采购成本" @input="numContro($event, 1)" />
                    </div>

                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">预计物流成本:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.logisticsCost" type="number" name="物流成本" @focus="cleKong(2)" label=""
                            placeholder="请输入预计物流成本" @input="numContro($event, 2)" />
                    </div>


                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">预计其他成本:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.otherCosts" name="其他成本" type="number" @focus="cleKong(3)" label=""
                            placeholder="请输入预计其他成本" @input="numContro($event, 3)" />
                    </div>
                </div>

                <van-cell-group>
                    <van-switch-cell v-model="isImagesInfo" title="录入车辆图像信息"
                        :style="{ color: 'green', fontSize: '30px', fontWeight: 'bloder' }" cell-size="large" />
                </van-cell-group>
                <div v-show="isImagesInfo">
                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">上传车辆照片（最多6张）:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }" name="" label=""
                            placeholder="取车位置">
                            <template #input>
                                <UploadImg v-if="!isWechat" ref="business_license" :maxCount="6" />
                                <p v-else>
                                    <span class="imgbox" v-for="(item, index) in WXimg" :key="index">
                                        <van-image class="vanImage" width="80" height="80" :src="item"
                                            @click="bigImg(item)">
                                            <van-icon class="iconX" name="close"
                                                @click="delImgHan('arrPhoto', index)" />
                                        </van-image>
                                    </span>
                                    <span v-if="WXimg.length <= 5" class="uploaderImg"
                                        @click="WeChooseImage(6, 'ciagou')">
                                        <van-icon class="iconz" name="plus" />
                                    </span>
                                </p>
                            </template>
                        </van-field>
                    </div>
                </div>

                <van-cell-group>
                    <van-switch-cell v-model="isConnectInfo" title="录入车辆联系人信息"
                        :style="{ color: 'green', fontSize: '30px', fontWeight: 'bloder' }" cell-size="large" />
                </van-cell-group>
                <div v-show="isConnectInfo">
                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">联系人姓名:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.carOwner" name="" label="" placeholder="请输入联系人姓名" />
                    </div>




                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">联系人手机:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.carOwnerPhone" name="" label="" placeholder="请输入联系人手机"></van-field>
                    </div>

                </div>
                <van-cell-group>
                    <van-switch-cell v-model="isTowage" title="发起车辆拖运调度"
                        :style="{ color: 'green', fontSize: '30px', fontWeight: 'bloder' }" cell-size="large" />
                </van-cell-group>
                <div v-show="isTowage">
                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">运输批次编号:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.dismant_batch_number" name="" label="" placeholder="请输入品牌" />
                    </div>
                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">车辆拖运方式:</div>
                        <div :style="{ border: '0px solid green', borderBottomWidth: '1px',padding:'10px 15px' }">
                                <van-radio-group v-model="form.deliveryTypeId" direction="horizontal" >
                                    <van-radio name="1">内送</van-radio>
                                    <van-radio name="3" :style="{marginLeft:'30px'}">外送</van-radio>
                                </van-radio-group>
                        </div>
                        
                    </div>
                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">车辆拖运时间:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.deliveryTypeIdTime" name="" label="" placeholder="请选择拖运时间" @click="showDateTime=true"/>

                        <van-popup v-model="showDateTime" position="bottom">
                            <van-datetime-picker v-model="currentDate" type="datetime" title="请选择拖运时间" :formatter="formatter" confirm-button-text="确定" @confirm="form.deliveryTypeIdTime=$moment(currentDate).format('YYYY-MM-DD H:mm:ss');showDateTime=false" @cancel="showDateTime=false"/>
                        </van-popup>

                        
                    </div>
                    <div class="scrap-content-div" :style="{ margin: '15px 15px' }">
                        <div :style="{ margin: '5px 10px' }">车辆拖运地址:</div>
                        <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                            v-model="form.address" name="" label="" placeholder="请输入车辆拖运地址" rows="3" type="textarea" />
                    </div>
                </div>

                <div style="margin: 50px 16px 16px;">
                    <van-button round block type="info" @click="onSubmit">确定</van-button>
                </div>
            </van-form>
        </div>
        <van-popup v-model="show" :close-on-click-overlay="false" class="loading"><van-loading
                size="40px">上传中。。。</van-loading></van-popup>
    </div>

</template>
<script>

</script>

<script>
import UploadImg from "../../components/upload_img";
import { Toast ,Dialog} from 'vant'
import { is_post } from "../../Http/api";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        components: {
            UploadImg,
        },
        data() {
            return {
                showDateTime:false,
                 currentDate:new Date(),
                 isCarInfo:true,
                 isPriceInfo:false,
                 isImagesInfo:false,
                 isConnectInfo:false,
                 isTowage:false,
                 uploader:[],
                 show:false,//上传照片加载
                 showMap:false,//地图
                 second_show:false,//验证码
                 content_show:false,//提交完成
                 form:{
                    'carNumberPlate':"",//车牌号
                    'vehicleBrand':"",//品牌
                    'vehicleModel':"",//型号
                    'vin':"",//车架号
                    'image':[],//图片
                    'carOwner':"",//联系人
                    'carOwnerPhone':"",//联系电话
                    'purchaseAmount':0,//采购净额
                    'logisticsCost':0,//物流成本   
                    'otherCosts':0,//其他成本
                    'dismant_batch_number':'',//运输批次编号
                    'deliveryTypeId':'',//运输方式
                    'deliveryTypeIdTime':'',//运输日期时间
                    'address':'',//运输地址
                    'principalUserIsSelf':1,//采购负责人，0，别人，1自己
                 },
                 WXimg:[],
                 wximagelocalids:[],//判断上传张数
                 toast:null,
            }
        },
        mounted () {
            this.wcConfig()
        },
         watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        created () {
          
        },
        methods: {
            
            formatter(type, val) {
                if (type === 'year') {
                    return `${val[2]+val[3]}年`;
                } else if (type === 'month') {
                    return `${val}月`;
                }else if (type === 'day') {
                    return `${val}日`;
                }else if (type === 'day') {
                    return `${val}日`;
                }else if (type === 'hour') {
                    return `${val}时`;
                }else if (type === 'minute') {
                    return `${val}分`;
                }
                return val;
            },
            numContro(value,item){
                 let val = (value && value.split("")) || []
                 switch (item) {
                     case 1:
                          this.form.purchaseAmount = val.join("").match(/^\d*(\.?\d{0,2})/g)[0] || null;
                         break;
                     case 2:
                          this.form.logisticsCost = val.join("").match(/^\d*(\.?\d{0,2})/g)[0] || null;
                         break;
                     case 3:
                          this.form.otherCosts = val.join("").match(/^\d*(\.?\d{0,2})/g)[0] || null;
                         break;
                 
                     default:
                         break;
                 }
               
            },
            cleKong(i){
                switch (i) {
                    case 1:
                        this.form.purchaseAmount = null
                        break;
                    case 2:
                        this.form.logisticsCost = null
                        break;
                    case 3:
                        this.form.otherCosts = null
                        break;
                
                    default:
                        break;
                }
            },
            gouot() {
                this.$router.push('/guide')
            },
            //时间倒计时结束
            finish() {
                this.second_show = false;
            },
            //打开地图选中
            isMap(){
               this.showMap = true
            },
            //提交信息
            async onSubmit() {
                try{
                    if(!this.isTowage){
                        this.form.dismant_batch_number=''
                        this.form.deliveryTypeId=''
                        this.form.deliveryTypeIdTime=''
                        this.form.address=''
                    }else{
                        if (!this.form.vin) {
                            this.$toast.fail('车架号不能为空')
                            return false
                        }
                        if (!this.form.carOwner) {
                            this.$toast.fail('联系人不能为空')
                            return false
                        }
                        if (!this.form.carOwnerPhone) {
                            this.$toast.fail('联系电话不能为空')
                            return false
                        }
                        
                        if (!this.form.deliveryTypeIdTime) {
                            this.$toast.fail('请设置拖车时间')
                            return false
                        }

                        if (!this.form.address) {
                            this.$toast.fail('请填写拖车地址')
                            return false
                        }

                        if (!this.form.deliveryTypeId) {
                            this.$toast.fail('请选择拖车方式')
                            return false
                        }
                    }
                    this.form.image = this.WXimg
                    this.form.principalUserIsSelf=1
                    let data = encipherMent( JSON.stringify(this.form))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    let url=''
                    let confirmMsg=''
                    if(!this.isTowage){
                        url='/index.php/end_of_life_vehicles/phone_add'
                        confirmMsg='您确定录入采购单，无需同时发起运输调度？'
                    }else{
                        url='/index.php/dispatch/add'
                        confirmMsg='您确定录入采购单并发起运输调度？'
                    }

                    Dialog.confirm({
                        title: '温馨提示',
                        message: confirmMsg,
                    })
                    .then(async() => {
                        // on confirm
                        const res = await is_post( url,param)
                        if(res.code == 0){
                            this.show = false
                            if(!this.isTowage){
                                this.$toast.success("新增采购单成功")
                            }else{
                                this.$toast.success("新增采购单和拖车订单成功")
                            }
                            
                            this.$router.push('/guide')
                            
                        }else{
                            this.$toast.fail(res.msg)
                        }
                    })
                    .catch(() => {
                        // on cancel
                    });
                    
                }
                catch (err){
                    this.show = false
                    this.$toast.fail(err.msg)
                }
            },
            //上传照片
            afterRead(e){
                let file=e.target.files[0]
                /* console.log("file",e.target.files[0]) */
                this.show = true
                let params = new FormData(); //创建form对象
                params.append("file", file);
                params.append("w", 200);
                params.append("h", 200);
                this.$http
                    .post("/index.php/index/upload/index", params)
                    .then((res) => {
                        if (res.data.code == 0) {
                      
                       this.VinDiscern(res.data.data)
                    } else {
                        this.show= false
                       
                       this.$toast.fail(res.data.msg)
                    }
                })
                .catch(()=>{
                    
                })
            },
            //失败VIN
            async VinDiscern(img){
                try{
                    let data = encipherMent( JSON.stringify({url:img}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post( "/index.php/index/bid_management.bid_order/apiBaiDu",param)
                    console.log(res)
                    this.form.vin = res.data
                    this.show = false
                }
                catch{
                    this.show = false
                }
               
            }
        },
    }
</script>

<style lang="scss" scoped>
.scrap {

    // padding: 10px;
    .title {
        text-align: center;
        font-size: 26px;
        margin-top: 10px;
        padding: 10px;
    }

    .scrap-content {
        margin-top: 70px;

        .scrap-content-div {
            margin-bottom: 20px;
            margin: 15px 15px;

            input: {
                border: 0px solid green;
                border-bottom-width: 1px;
            }
        }
    }

    .btn-p {
        margin: 5px;
    }
}

.van-loading {
    height: 75px !important;
    background: rgba(0, 0, 0, 0.7);
}

.accomplish {
    width: 80%;
    font-size: 26px;
    padding: 20px;
    margin-right: 0 !important;

    .accomplish-title {
        text-align: center;
    }

    .accomplish-zi {
        text-indent: 2em;
    }
}

.btn-Vin {
    margin: 10px;

}

.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;

    span {
        display: inline-block;
    }

    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}

.uploaderImg {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    position: relative;

    .iconz {
        position: absolute;
        top: 42%;
        left: 38%;

    }
}

.vanImage {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .iconX {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>
